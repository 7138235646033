/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slide-process-mit {
  padding: 40px 0 140px;
  position: relative;
  text-align: center;
}

#process-image-slider {
  width: 100%;
  margin: 0 auto;
}

#process-image-slider-slides {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

#process-image-slider-slides .process-image-slide {
  flex-shrink: 0;
  align-items: flex-start;
  width: 100%;
  transition: transform 1s;
  overflow: hidden;
  position: relative;
}

#process-image-slider-slides.active-0 .process-image-slide {
  transform: translateX(0);
}

#process-image-slider-slides.active-1 .process-image-slide {
  transform: translateX(-100%);
}

#process-image-slider-slides.active-2 .process-image-slide {
  transform: translateX(-200%);
}

#process-image-slider-slides.active-3 .process-image-slide {
  transform: translateX(-300%);
}

#process-image-slider-slides.active-4 .process-image-slide {
  transform: translateX(-400%);
}

#process-image-slider-slides.active-5 .process-image-slide {
  transform: translateX(-500%);
}

#process-image-slider-slides.active-6 .process-image-slide {
  transform: translateX(-600%);
}

#process-image-slider-slides.active-7 .process-image-slide {
  transform: translateX(-700%);
}

#process-image-slider-slides.active-8 .process-image-slide {
  transform: translateX(-800%);
}

#process-image-slider-slides.active-9 .process-image-slide {
  transform: translateX(-900%);
}

#process-image-slider-slides.active-10 .process-image-slide {
  transform: translateX(-1000%);
}

#process-image-slider-slides.active-11 .process-image-slide {
  transform: translateX(-1100%);
}

#process-image-slider-slides.active-12 .process-image-slide {
  transform: translateX(-1200%);
}

#process-image-slider-slides.active-13 .process-image-slide {
  transform: translateX(-1300%);
}

#process-image-slider-slides.active-14 .process-image-slide {
  transform: translateX(-1400%);
}

#process-image-slider-slides.active-15 .process-image-slide {
  transform: translateX(-1500%);
}

#process-image-slider-slides.active-16 .process-image-slide {
  transform: translateX(-1600%);
}

#process-image-slider-slides .process-image-slide p {
  padding: 20px 40px 0;
}

.process-slides-navigation-button {
  position: absolute;
  top: 330px;
  z-index: 100;
  cursor: pointer;
}

#process-slides-next {
  right: 20px;
}

#process-slides-prev {
  left: 20px;
}
