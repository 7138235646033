/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slides {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  height: 100%;
  min-height: $main-box-height;
}

#slides .slide {
  flex-shrink: 0;
  align-items: flex-start;
  width: 100%;
  transition: transform 1s;
  overflow: hidden;
  position: relative;
}

#slides.active-0 .slide {
  transform: translateX(0);
}

#slides.active-1 .slide {
  transform: translateX(-100%);
}

#slides.active-2 .slide {
  transform: translateX(-200%);
}

#slides.active-3 .slide {
  transform: translateX(-300%);
}

#slides.active-4 .slide {
  transform: translateX(-400%);
}

#slides.active-5 .slide {
  transform: translateX(-500%);
}

#slides.active-6 .slide {
  transform: translateX(-600%);
}

#slides:not(.active-6) #slide-concept {
  height: $main-box-height;
}

#slides.active-7 .slide {
  transform: translateX(-700%);
}

#slides:not(.active-7) #slide-nanotubes {
  height: $main-box-height;
}

#slides.active-8 .slide {
  transform: translateX(-800%);
}

#slides:not(.active-8) #slide-artist {
  height: $main-box-height;
}

#slides.active-9 .slide {
  transform: translateX(-900%);
}

#slides:not(.active-9) #slide-process-mit {
  height: $main-box-height;
}

#slides.active-10 .slide {
  transform: translateX(-1000%);
}

#slides:not(.active-10) #slide-press {
  height: $main-box-height;
}

#slides.active-11 .slide {
  transform: translateX(-1100%);
}

#slides:not(.active-11) #slide-collaboration {
  height: $main-box-height;
}

#slides.active-12 .slide {
  transform: translateX(-1200%);
}

#slides:not(.active-12) #slide-contact {
  height: $main-box-height;
}

.slides-navigation-button {
  position: absolute;
  top: 330px;
  z-index: 100;
  cursor: pointer;
}

#slides-next {
  right: 20px;
}

#slides-prev {
  left: 20px;
}

.slide-content {
  padding: 0 60px;
  min-height: $main-box-height;
}

/* stylelint-disable */
@import "intro";
@import "title";
@import "diamond-video";
@import "intro-text";
@import "diamond-animation";
@import "diamond-case";
@import "concept";
@import "nanotubes";
@import "artist";
@import "process-mit";
@import "press";
@import "collaboration";
@import "contact";
/* stylelint-enable */
