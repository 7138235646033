/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slide-contact {
  padding-top: 40px;
  padding-bottom: 140px;
}

#slide-contact p {
  font-size: 20px;
}
