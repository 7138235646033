/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slide-collaboration {
  padding-top: 40px;
  padding-bottom: 140px;
  text-align: justify;
}

#slide-collaboration i {
  font-size: 15px;
}
