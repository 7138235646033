/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slide-concept {
  padding-top: 40px;
  padding-bottom: 140px;
}

#slide-concept p {
  text-align: justify;
}

#slide-concept .columns {
  font-size: 16px;
  text-align: justify;
}
