/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slide-intro {
  text-align: center;
}

#slide-intro p {
  position: absolute;
  top: 315px;
  left: 0;
  right: 0;
  padding: 0 60px;
}
