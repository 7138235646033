/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slide-diamond-video {
  position: absolute;
  bottom: 0;
  top: 0;
  background-color: black;
}

#slide-diamond-video video {
  height: $main-box-height;
  width: 100%;
}

#slide-diamond-video p {
  position: absolute;
  bottom: 100px;
  left: 100px;
  right: 100px;
}
