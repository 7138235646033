$family-sans-serif: "Roboto", sans-serif;

$body-background-color	: #1e1e1e;
$body-color	: white;

$main-box-height: 700px;
$main-box-width: 950px;

$link: white;
$link-hover: white;
