/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slide-artist {
  padding-top: 40px;
  padding-bottom: 140px;
}

#slide-artist img {
  width: 100%;
}
