/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slides #slide-diamond-case {
  position: relative;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

#case-image-slider {
  position: absolute;
  bottom: 270px;
  top: 40px;
}

#case-image-slider-slides {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

#case-image-slider-slides img {
  max-height: 100%;
}

#case-image-slider-slides .case-image-slide {
  flex-shrink: 0;
  align-items: flex-start;
  width: 100%;
  transition: transform 1s;
  position: relative;
}

#case-image-slider-slides.active-0 .case-image-slide {
  transform: translateX(0);
}

#case-image-slider-slides.active-1 .case-image-slide {
  transform: translateX(-100%);
}

#case-image-slider-slides.active-2 .case-image-slide {
  transform: translateX(-200%);
}

#case-image-slider-slides.active-3 .case-image-slide {
  transform: translateX(-300%);
}

#case-image-slider-slides.active-4 .case-image-slide {
  transform: translateX(-400%);
}

#case-image-slider-controls {
  position: absolute;
  bottom: 100px;
  margin: auto;
  width: 40px;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

#case-image-slider-controls li {
  padding: 0 3px;
}

#case-image-slider-controls .slider-dot {
  border: 1px solid white;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  display: block;
  cursor: pointer;
}

#case-image-slider-controls .slider-dot.active {
  background-color: white;
}

#slides #slide-diamond-case .case-image-slide p {
  padding: 20px 0;
  margin: auto;
  max-width: 500px;
  font-size: 15px;
}

#slides #slide-diamond-case .case-image-slide p .small {
  font-size: 13px;
}

#slides #slide-diamond-case .case-image-slide p .smaller {
  font-size: 11px;
}
