/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slide-nanotubes {
  padding-top: 40px;
  padding-bottom: 140px;
}

#slide-nanotubes .columns.nanotubes {
  margin-right: -60px;
}

#slide-nanotubes .columns.nanotubes .column:last-child {
  padding-right: 0;
}

#nanotubes-image {
  object-fit: cover;
  object-position: 0 0;
  width: 600px;
  height: 283px;
}
