/* stylelint-disable */
@import "variables";
/* stylelint-enable */

#slide-diamond-animation {
  text-align: center;
}

#diamond-animation {
  position: absolute;
  bottom: 270px;
  z-index: 91;
  transition: filter 3s;
  margin: auto;
  left: 0;
  right: 0;
}

@keyframes blacken {
  from {
    filter: brightness(100%);
  }

  to {
    filter: brightness(1%);
  }
}

#slides.active-4 #diamond-animation {
  animation-duration: 3s;
  animation-name: blacken;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

#diamond-base {
  position: absolute;
  bottom: 0;
  z-index: 90;
  margin: auto;
  left: 0;
  right: 0;
  min-height: 500px;
}
